<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>采购管理</el-breadcrumb-item>
      <el-breadcrumb-item>对公采购单</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card">
      <el-button type="primary" @click="opentan()">创建采购单</el-button>
      <!-- 采购列表 -->
      <el-table :data="caigoudanliat" stripe style="width: 100%">
        <el-table-column sortable prop="date" label="日期" width="180"></el-table-column>
        <el-table-column prop="purchase_user" label="采购人" width="180"></el-table-column>
        <el-table-column prop="goods_info" label="采购物品" width="180"></el-table-column>
        <el-table-column prop="status_desc" label="状态" width="180"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-view" @click="editfun(scope.row.id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>

    <!-- 添加弹窗 -->
    <el-dialog title="创建采购单" :visible.sync="dialogFormVisible" :before-close="handleClose" width="95%">
      <el-form ref="dform" :model="dform" label-width="100px" :rules="rules">
        <el-form-item label="采购人" label-width="120px">
          <div class="el-select"><el-input v-model="shenname" disabled></el-input></div>
        </el-form-item>

        <el-form-item label="选择供应商" label-width="120px" prop="supplier_id">
          <el-select v-model="dform.supplier_id" placeholder="未选择" clearable>
            <el-option v-for="item in gonglist" :key="item.value" :label="item.name" :value="item.value" @click.native="chengng(item.id)"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="采购公司" label-width="120px" prop="company_id">
          <el-select v-model="dform.company_id" placeholder="未选择" clearable>
            <el-option v-for="item in canggongsi" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="新增采购合同审批" label-width="130px">
          <el-button type="primary" @click="xinhe">点击上传</el-button>
        </el-form-item>

        <!-- 内层弹窗 -->
        <el-dialog width="30%" title="采购合同申请" :visible.sync="innerVisible" :before-close="hetongguan" append-to-body>
          <el-form-item label="合同标题" label-width="120px">
            <div class="el-select"><el-input v-model="dform.contract_name" placeholder="请输入合同标题"></el-input></div>
          </el-form-item>
          <el-form-item label="采购合同金额" label-width="120px">
            <div class="el-select">
              <el-input type="number" v-model="contract_amountss" @input="hetongjiner(contract_amountss)" placeholder="请输入采购合同金额"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="合同审核人" label-width="120px">
            <el-select v-model="dform.contract_auth_user" placeholder="请选择合同审核人" clearable>
              <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="合同附件" label-width="120px">
            <el-upload
              accept=".pdf, .jpg, .jpeg, .png"
              class="upload-demo"
              action=""
              :on-preview="handlePreview"
              :on-remove="
                (file, fileList) => {
                  handleRemove(file, fileList, this.dform.goumai_fapiao)
                }
              "
              :before-remove="beforeRemove"
              :http-request="httpRequest"
              :multiple="false"
              :limit="1"
              :on-exceed="handleExceed"
              :file-list="dform.goumai_fapiao"
            >
              <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('合同附件')">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <span slot="footer" class="dialog-footer">
            <el-button @click="hetongxiao()">取 消</el-button>
            <el-button type="primary" @click="hetongque()">确定</el-button>
          </span>
        </el-dialog>
      </el-form>

      <!--    列表开始-->
      <el-table :data="tableData" ref="multipleTable" stripe style="width: 100%" tooltip-effect="dark" @selection-change="handleSelectionChange" height="400" align="center">
        <el-table-column type="selection" width="10"></el-table-column>
        <el-table-column prop="apply_no" label="对公采购申请编号" width="90" align="center"></el-table-column>
        <el-table-column prop="apply_user" label="采购申请人" width="90" align="center"></el-table-column>
        <el-table-column prop="house_name" label="入库仓库" width="100" align="center"></el-table-column>
        <el-table-column prop="company_name" label="采购公司" width="120" align="center"></el-table-column>
        <el-table-column prop="goods_name" label="配件名称" width="100" align="center"></el-table-column>

        <el-table-column prop="need_num" label="申请数量" width="90" align="center"></el-table-column>
        <el-table-column label="采购数量" width="120">
          <template slot-scope="scope">
            <el-input v-model="scope.row.num" min="0" @input="scope.row.num = inputChecks(scope.row.num)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="采购总价" width="150">
          <template slot-scope="scope">
            <el-input min="0" v-model="scope.row.amount" @input="scope.row.amount = inputCheck(scope.row.amount)"></el-input>
          </template>
        </el-table-column>

        <el-table-column prop="address" label="收货地址" width="170"></el-table-column>

        <el-table-column label="到货时间">
          <template slot-scope="scope">
            <el-date-picker v-model="scope.row.receive_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" :picker-options="pickerOptions"></el-date-picker>
          </template>
        </el-table-column>

        <el-table-column label="采购备注" width="100" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.item_remark" type="text"></el-input>
          </template>
        </el-table-column>

        <el-table-column prop="heji" label="单价" align="center">
          <template slot-scope="scope">
            {{ Number(scope.row.amount) / Number(scope.row.num) + '元' }}
          </template>
        </el-table-column>
      </el-table>
      <div>其他费用信息(合同内的其他费用)</div>
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAddZJ">新增</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="danger" plain icon="el-icon-delete" size="mini" @click="handleDeleteZJ">删除</el-button>
        </el-col>
      </el-row>
      <el-table
        :data="dform.other_fees"
        ref="zztable"
        @selection-change="handleSelectionChangezz"
        :row-class-name="zzTableRowClassName"
        style="width: 50%"
        show-summary
        :summary-method="getSummaries"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="序号" type="index" width="50" />

        <el-table-column prop="fee_name" label="费用名称">
          <template slot-scope="scope">
            <el-input v-model="scope.row.fee_name" min="0" placeholder="请输入费用名称" type="text"></el-input>
          </template>
        </el-table-column>

        <el-table-column prop="fee_amount" label="费用金额(元)">
          <template slot-scope="scope">
            <el-input v-model="scope.row.fee_amount" min="0" placeholder="请输入费用金额" @input="scope.row.fee_amount = feiinputCheck(scope.row.fee_amount)"></el-input>
          </template>
        </el-table-column>

        <el-table-column prop="fee_remark" label="费用备注">
          <template slot-scope="scope">
            <el-input v-model="scope.row.fee_remark" min="0" placeholder="请输入费用备注" type="text"></el-input>
          </template>
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="quanxiao()">取 消</el-button>
        <el-button type="primary" @keyup.enter.native="quanque()" @click="quanque()">确定</el-button>
      </span>
    </el-dialog>

    <!-- 采购单详情 -->
    <el-dialog title="采购单详情" :visible.sync="caixiangkaiguan" :before-close="daxiangguan" width="80%">
      <div class="tan">
        <div class="let">申请人</div>
        <div class="el-select"><el-input v-model="purchase_user.user_name" disabled></el-input></div>
      </div>
      <div class="tan">
        <div class="let">供应商</div>
        <div class="el-select"><el-input v-model="supplier.name" disabled></el-input></div>
      </div>
      <div class="tan">
        <div class="let">采购公司</div>
        <el-select v-model="form.company_id" placeholder="未选择" clearable :disabled="contract_status == 4 || contract_status == 2 ? true : false">
          <el-option v-for="item in canggongsi" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="btnshang">
        <el-button type="primary" @click="xianghe" v-if="allow_preview_contract == 1">新建审批合同</el-button>
        <el-button type="primary" @click="xiangzhenghe" v-if="allow_release_contract == 1">上传正式合同</el-button>
      </div>

      <!-- 详情新建合同弹窗 -->
      <el-dialog width="30%" title="新建采购合同审批" :visible.sync="xiangxinheshen" :before-close="xiangxinheshenguan" append-to-body>
        <el-form :model="hetong">
          <el-form-item label="合同标题" label-width="120px">
            <div class="el-select"><el-input v-model="hetong.contract_name" placeholder="请输入合同标题"></el-input></div>
          </el-form-item>
          <el-form-item label="采购合同金额" label-width="120px">
            <div class="el-select">
              <el-input type="number" v-model="hetong.contract_amounts" @input="hetongjinerss(hetong.contract_amounts)" placeholder="请输入采购合同金额"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="合同审核人" label-width="120px">
            <el-select v-model="hetong.contract_auth_user" placeholder="请选择合同审核人" clearable>
              <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="合同附件" label-width="120px">
            <el-upload
              accept=".pdf, .jpg, .jpeg, .png"
              class="upload-demo"
              action=""
              :on-preview="endhandlePreview"
              :on-remove="
                (file, fileList) => {
                  endhandleRemove(file, fileList, this.hetong.goumai_fapiao)
                }
              "
              :before-remove="endbeforeRemove"
              :http-request="endhttpRequest"
              :multiple="false"
              :limit="1"
              :on-exceed="endhandleExceed"
              :file-list="hetong.goumai_fapiao"
            >
              <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('合同附件')">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="xianghequxiao()">取 消</el-button>
          <el-button type="primary" @click="xianghequeding()">确定</el-button>
        </span>
      </el-dialog>

      <!-- 正式合同弹窗 -->
      <el-dialog width="30%" title="新建采购合同审批" :visible.sync="zhengxiangxinheshen" :before-close="zhengxiangxinheshenguan" append-to-body>
        <el-form :model="zhenghetong">
          <el-form-item label="合同标题" label-width="120px">
            <div class="el-select"><el-input v-model="zhenghetong.contract_name" placeholder="请输入合同标题"></el-input></div>
          </el-form-item>
          <el-form-item label="采购合同金额" label-width="120px">
            <div class="el-select">
              <el-input
                type="number"
                v-model="zhenghetong.contract_amounts"
                @input="zhenghetongjinerss(zhenghetong.contract_amounts)"
                placeholder="请输入采购合同金额"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="合同审核人" label-width="120px">
            <el-select v-model="zhenghetong.contract_auth_user" placeholder="请选择合同审核人" clearable>
              <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="合同附件" label-width="120px">
            <el-upload
              accept=".pdf, .jpg, .jpeg, .png"
              class="upload-demo"
              action=""
              :on-preview="zhengendhandlePreview"
              :on-remove="
                (file, fileList) => {
                  zhengendhandleRemove(file, fileList, this.zhenghetong.goumai_fapiao)
                }
              "
              :before-remove="zhengendbeforeRemove"
              :http-request="zhengendhttpRequest"
              :multiple="false"
              :limit="1"
              :on-exceed="zhengendhandleExceed"
              :file-list="zhenghetong.goumai_fapiao"
            >
              <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('合同附件')">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="zhengxianghequxiao()">取 消</el-button>
          <el-button type="primary" @click="zhengxianghequeding()">确定</el-button>
        </span>
      </el-dialog>

      <div class="title">
        采购货物信息
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="xiangmingjia" :disabled="contract_status == 4 || contract_status == 2 ? true : false">
          新增
        </el-button>
      </div>
      <el-table :data="items" stripe style="width: 100%">
        <el-table-column prop="apply_user" label="采购申请人" width="90" align="center"></el-table-column>
        <el-table-column prop="house_name" label="入库仓库" width="100" align="center"></el-table-column>
        <el-table-column prop="company_name" label="采购公司" width="120" align="center"></el-table-column>
        <el-table-column prop="goods_name" label="配件名称" width="100" align="center"></el-table-column>
        <el-table-column prop="goods_no" label="配件编号" width="100" align="center"></el-table-column>
        <el-table-column prop="goods_type" label="配件分类" width="100" align="center"></el-table-column>
        <el-table-column prop="apply_num" label="申请数量" width="100" align="center"></el-table-column>
        <el-table-column prop="buy_num" label="采购数量" width="100" align="center"></el-table-column>
        <el-table-column prop="buy_amount" label="购买总价" width="100" align="center"></el-table-column>
        <el-table-column prop="buy_price" label="购买单价" width="100" align="center"></el-table-column>
        <el-table-column prop="receive_time" label="预计到货时间" width="100" align="center"></el-table-column>
        <el-table-column prop="apply_remark" label="申请备注" width="100" align="center"></el-table-column>
      </el-table>

      <!-- 详情代采购增加 -->
      <el-dialog width="90%" title="增加采购明细" :visible.sync="xiangjiaVisible" :before-close="xiangqingyeguannnn" append-to-body>
        <el-table :data="xiangtableData" ref="table" style="width: 100%" @select="handleSelect" stripe>
          <el-table-column type="selection" width="10"></el-table-column>
          <el-table-column prop="apply_no" label="对公采购申请编号" width="90" align="center"></el-table-column>
          <el-table-column prop="apply_user" label="采购申请人" width="90" align="center"></el-table-column>
          <el-table-column prop="house_name" label="入库仓库" width="90" align="center"></el-table-column>
          <el-table-column prop="company_name" label="采购公司" width="100" align="center"></el-table-column>
          <el-table-column prop="goods_name" label="配件名称" width="100" align="center"></el-table-column>

          <el-table-column prop="need_num" label="申请数量" width="90" align="center"></el-table-column>
          <el-table-column label="购买数量" width="120">
            <template slot-scope="scope">
              <el-input v-model="scope.row.num" min="0" @input="scope.row.num = inputChecks(scope.row.num)" v-if="scope.row.if"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="采购总价" width="150">
            <template slot-scope="scope">
              <el-input min="0" v-model="scope.row.amount" @input="scope.row.amount = inputCheck(scope.row.amount)" v-if="scope.row.if"></el-input>
            </template>
          </el-table-column>

          <el-table-column prop="address" label="收货地址" width="170"></el-table-column>

          <el-table-column label="到货时间">
            <template slot-scope="scope">
              <el-date-picker
                v-model="scope.row.receive_time"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
                :picker-options="pickerOptions"
                v-if="scope.row.if"
              ></el-date-picker>
            </template>
          </el-table-column>

          <!-- <el-table-column prop="apply_remark" label="申请备注" width="100" align="center"></el-table-column> -->

          <el-table-column label="采购备注" width="100" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.item_remark" type="text" v-if="scope.row.if"></el-input>
            </template>
          </el-table-column>

          <el-table-column prop="heji" label="单价" align="center">
            <template slot-scope="scope">
              {{ Number(scope.row.amount) / Number(scope.row.num) }}
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="xiangmingxiquxiao()">取 消</el-button>
          <el-button type="primary" @click="xiangmingxique()">确定</el-button>
        </span>
      </el-dialog>

      <!-- 费用信息 -->
      <div class="title">
        其他费用信息
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="feimingjia" :disabled="contract_status == 4 || contract_status == 2 ? true : false">
          新增
        </el-button>
      </div>
      <div class="hebox">
        <el-table :data="feiyonglist" style="width: 50%" show-summary>
          <el-table-column label="序号" type="index" width="50" />
          <el-table-column prop="fee_name" label="费用名称"></el-table-column>
          <el-table-column prop="fee_amount" label="费用金额"></el-table-column>
          <el-table-column prop="fee_remark" label="费用备注"></el-table-column>
        </el-table>
        <div class="right-box">
          <div>
            <a href="javascript:void(0)" v-if="yupreview_contract == undefined || yupreview_contract == ''" @click="notiao" class="asas">预览合同</a>
            <a v-if="yupreview_contract !== undefined" href="" id="link" @click="funs" target="_search" class="asas">预览合同</a>
          </div>
          <div class="rig">
            <a href="javascript:void(0)" v-if="zhengcontract_images == undefined || zhengcontract_images == ''" @click="nozhengtiao" class="asass">正式合同</a>
            <a v-if="zhengcontract_images !== undefined" href="" id="links" @click="funzheng" target="_search" class="asass">正式合同</a>
          </div>
        </div>
      </div>
      <el-dialog width="50%" title="费用明细新增" :visible.sync="xiangfeiyongtan" :before-close="xiangfeimoren" append-to-body>
        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="feihandleAddZJ">新增</el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button type="danger" plain icon="el-icon-delete" size="mini" @click="feihandleDeleteZJ">删除</el-button>
          </el-col>
        </el-row>
        <el-table
          :data="xiangxinzengfeilist"
          ref="zztable"
          @selection-change="feihandleSelectionChangezz"
          :row-class-name="feizzTableRowClassName"
          style="width: 80%"
          show-summary
          :summary-method="getSummaries"
        >
          <el-table-column type="selection" width="55" align="center" />
          <el-table-column label="序号" type="index" width="50" />

          <el-table-column prop="fee_name" label="费用名称">
            <template slot-scope="scope">
              <el-input v-model="scope.row.fee_name" min="0" placeholder="请输入费用名称" type="text"></el-input>
            </template>
          </el-table-column>

          <el-table-column prop="fee_amount" label="费用金额(元)">
            <template slot-scope="scope">
              <el-input v-model="scope.row.fee_amount" min="0" placeholder="请输入费用金额" @input="scope.row.fee_amount = feiinputCheck(scope.row.fee_amount)"></el-input>
            </template>
          </el-table-column>

          <el-table-column prop="fee_remark" label="费用备注">
            <template slot-scope="scope">
              <el-input v-model="scope.row.fee_remark" min="0" placeholder="请输入费用备注" type="text"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="feiyongtianjiaquxiao()">取 消</el-button>
          <el-button type="primary" @click="feiyongtainjiaqueding()">确定</el-button>
        </span>
      </el-dialog>

      <div class="gong">
        <div class="title">
          新增付款申请
          <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="dianfukuanshen" :disabled="contract_status == 4 ? false : true">新增付款申请</el-button>
        </div>
        <div class="tan">
          <div class="let">供应商</div>
          <div class="el-select"><el-input v-model="supplier.name" disabled></el-input></div>
        </div>
        <div class="tan">
          <div class="let">开户银行</div>
          <div class="el-select"><el-input v-model="supplier.billing_bank" disabled></el-input></div>
        </div>
        <div class="tan">
          <div class="let">银行账号</div>
          <div class="el-select"><el-input v-model="supplier.billing_cardno" disabled></el-input></div>
        </div>
        <div class="tan">
          <div class="let">未付金额</div>
          <div class="el-select"><el-input v-model="need_payed_amount" disabled></el-input></div>
        </div>
      </div>

      <!-- 新建付款申请 -->
      <el-dialog width="50%" title="新增付款申请" :visible.sync="xinzengfukuan" :before-close="xinzengfukuanguan" append-to-body>
        <div class="tans">
          <div class="let">供应商</div>
          <div class="el-select"><el-input v-model="supplier.name" disabled></el-input></div>
        </div>
        <div class="tans">
          <div class="let">开户银行</div>
          <div class="el-select"><el-input v-model="supplier.billing_bank" disabled></el-input></div>
        </div>
        <div class="tans">
          <div class="let">银行账号</div>
          <div class="el-select"><el-input v-model="supplier.billing_cardno" disabled></el-input></div>
        </div>
        <div class="tans">
          <div class="let">未付金额</div>
          <div class="el-select"><el-input v-model="need_payed_amount" disabled></el-input></div>
        </div>
        <div class="tans">
          <div class="let">选择审批人</div>
          <el-select v-model="fukuan.auth_user" placeholder="请选择审批人" clearable>
            <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value" @click.native="fuxuanzhong(item.value)"></el-option>
          </el-select>
        </div>
        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="fuhandleAddZJ">新增</el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button type="danger" plain icon="el-icon-delete" size="mini" @click="fuhandleDeleteZJ">删除</el-button>
          </el-col>
        </el-row>
        <el-table :data="fukuan.pay_list" ref="zztable" @selection-change="fuhandleSelectionChangezz" :row-class-name="fuzzTableRowClassName" style="width: 50%">
          <el-table-column type="selection" width="55" align="center" />
          <el-table-column label="序号" type="index" width="50" />
          <el-table-column prop="pay_amount" label="费用金额(元)">
            <template slot-scope="scope">
              <el-input v-model="scope.row.pay_amount" min="0" placeholder="请输入费用金额" @input="scope.row.pay_amount = feiinputCheck(scope.row.pay_amount)"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="fukuantianjiaquxiao()">取 消</el-button>
          <el-button type="primary" @click="fukantainjiaqueding()">确定</el-button>
        </span>
      </el-dialog>

      <div class="gong">
        <div class="title">
          回票信息
          <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="dianhuipiao" :disabled="contract_status == 4 ? false : true">新增回票信息</el-button>
        </div>
        <div class="tan">
          <div class="let">供应商</div>
          <div class="el-select"><el-input v-model="supplier.name" disabled></el-input></div>
        </div>

        <div class="tan">
          <div class="let">税号</div>
          <div class="el-select"><el-input v-model="supplier.tax_no" disabled></el-input></div>
        </div>
        <div class="tan">
          <div class="let">开户银行</div>
          <div class="el-select"><el-input v-model="supplier.billing_bank" disabled></el-input></div>
        </div>
        <div class="tan">
          <div class="let">银行账号</div>
          <div class="el-select"><el-input v-model="supplier.billing_cardno" disabled></el-input></div>
        </div>
        <div class="tan">
          <div class="let">未回票金额</div>
          <div class="el-select"><el-input v-model="need_returned_amount" disabled></el-input></div>
        </div>
      </div>
      <el-dialog width="50%" title="新增回票信息" :visible.sync="xinzenghuipiaokaiguan" :before-close="huipiaomorenguan" append-to-body>
        <div class="tans">
          <div class="let">供应商</div>
          <div class="el-select"><el-input v-model="supplier.name" disabled></el-input></div>
        </div>
        <div class="tans">
          <div class="let">税号</div>
          <div class="el-select"><el-input v-model="supplier.tax_no" disabled></el-input></div>
        </div>
        <div class="tans">
          <div class="let">开户银行</div>
          <div class="el-select"><el-input v-model="supplier.billing_bank" disabled></el-input></div>
        </div>
        <div class="tans">
          <div class="let">银行账号</div>
          <div class="el-select"><el-input v-model="supplier.billing_cardno" disabled></el-input></div>
        </div>
        <div class="tans">
          <div class="let">未回票金额</div>
          <div class="el-select"><el-input v-model="need_returned_amount" disabled></el-input></div>
        </div>
        <el-form :model="huipiaoform">
          <el-form-item label="发票种类" label-width="95px">
            <el-select v-model="huipiaoform.bill_type" placeholder="请选择发票种类" clearable>
              <el-option v-for="item in fapiao" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="回票金额" label-width="95px">
            <div class="el-select">
              <el-input type="number" v-model="huipiaoform.jiner" @input="huipiaojiner(huipiaoform.jiner)" placeholder="请输入回票金额"></el-input>
            </div>
          </el-form-item>

          <el-form-item label="选择审批人" label-width="95px">
            <el-select v-model="huipiaoform.auth_user" placeholder="请选择审批人" clearable>
              <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="发票截图" label-width="120px">
            <el-upload
              accept=".pdf, .jpg, .jpeg, .png"
              class="upload-demo"
              action=""
              :on-preview="zhengendhandlePreview"
              :on-remove="
                (file, fileList) => {
                  huihandleRemove(file, fileList, this.goumai_fapiaos)
                }
              "
              :before-remove="huibeforeRemove"
              :http-request="huihttpRequest"
              :multiple="false"
              :limit="1"
              :on-exceed="zhengendhandleExceed"
              :file-list="goumai_fapiaos"
            >
              <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('发票附件')">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="huipiaoquxiao()">取 消</el-button>
          <el-button type="primary" @click="huipiaoqueding()">确定</el-button>
        </span>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="daquxiao()">取 消</el-button>
        <el-button type="primary" @click="daqueding()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { InfoGet } from '../../../api/register'
import Fenye from '../../../components/Fenye.vue'
import { Upimg } from '@/api/upimg'
import {
  Daicai,
  Cangsou,
  Setdan,
  Caigoudanlist,
  Xiangqing,
  Gongyingsou,
  Canggongsi,
  Shenheren,
  Submit,
  Caifushu,
  Xiangmingxi,
  Xiangfeiyong,
  Xiangfukuan,
  huipiaotijiao,
  Endbiaoba
} from '../../../api/store'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      // 限制当前日期之前不可选
      pickerOptions: {
        //返回true不可选，返回false可选
        disabledDate: time => {
          return time.getTime() < Date.now() - 8.64e7
        }
      },

      yupreview_contract: '',
      zhengcontract_images: '',
      ///////////详情/////////////
      purchase_user: {},              // 购买的用户
      supplier: {},                   // 供应商信息
      company: {},                    // 采购公司的信息
      xiangxinheshen: false,          // 详情新建审批合同弹窗开关
      zhengxiangxinheshen: false,     // 详情正式合同弹窗开关
      items: [],                      // 采购明细
      xiangjiaVisible: false,         // 详情明细增加
      xiangtableData: [],             // 详情代采购
      feiyonglist: [],                // 单条的费用详情
      xiangfeiyongtan: false,         // 详情费用添加弹窗
      need_payed_amount: '',          // 供应商未支付金额
      need_returned_amount: '',       // 回票金额
      xinzengfukuan: false,           // 新增付款申请
      xinzenghuipiaokaiguan: false,   // 新增回票信息弹窗开关
      goumai_fapiaos: [],             // 回票图片储存
      huipiaoform: {
        jiner: '',
        supplier_id: '',              // 供应商id
        auth_user: '',                // 审核人id
        return_list: [
          {
            purchase_id: '',
            return_money: ''
          }
        ], // 回票明细
        bill_type: '', // 发票类型
        images: '' // 回票图片截图
      },

      fapiao: [
        { name: '请选择', value: '' },
        { name: '普通发票', value: 1 },
        { name: '增值税发票', value: 2 }
      ],

      ////////////////////////
      contract_status: '',              // 禁用状态
      allow_release_contract: '',       // 允许正式合同
      allow_preview_contract: '',       // 允许发布预览
      caixiangkaiguan: false,           // 采购单详情开关
      renlist: [],                      // 人员列表
      contract_amountss: '',            // 合同金额转换分之前
      innerVisible: false,              // 新增采购合同审批
      shenname: '',                     // 申请人
      created_time: '',                 // 创建时间
      endtableData: [],
      caigoudanliat: [],                // 采购单列表
      house_ids: '',                    // 选择仓库 显示对应仓库代采购事项
      canglist: [],                     // 仓库下拉数据
      gonglist: [],                     // 供应商下拉数据
      addimgtype: false,                // 上传控制
      purchase_id: '',                  // 采购单id
      dform: {
        company_id: '',                 // 采购公司id
        supplier_id: '',                // 供应商id
        goumai_fapiao: [],              // 合同附件数组
        contract_images: '',            // 上传图片地址
        items: [],                      // 明细
        contract_name: '',              // 合同标题
        preview_contract_images: '',    // 合同图片
        contract_amount: '',            // 合同总金额转换分之后
        contract_auth_user: '',         // 预览合同审批人
        other_fees: []                  // 其他费用信息
      },
      form: {
        company_id: '',                 // 采购公司id
        supplier_id: '',                // 供应商id
        id: ''
      },
      // 详情明细增加
      mingxi: {
        item_id: '0',                   // 采购单明细ID
        purchase_id: '',                // 采购单记录ID*
        apply_item: '',                 // 采购单申请明细ID*
        num: '',                        // 采购数量*
        amount: '',                     // 单价总金额(分)
        item_remark: '',                // 采购备注
        receive_time: ''                // 收货时间
      },
      // 费用选中传回后端的数据
      feiform: {
        item_id: '0',
        purchase_id: '',
        fee_name: '',
        fee_amount: '',
        fee_remark: ''
      },

      fukuan: {
        supplier_id: '',                // 供应商id
        auth_user: '',                  // 审核人id
        pay_list: []                    // 付款明细
      },

      // 合同提交
      hetong: {
        id: '',                         // 采购单id
        type: '1',                      // 1-初审合同   2-正式合同
        contract_name: '',              // 合同标题
        contract_images: '',            // 合同图片
        contract_amounts: '',           // 合同总金额(元)
        contract_amount: '',            // 合同总金额(分)
        contract_auth_user: '',         // 预览合同审批人
        goumai_fapiao: []               // 合同附件数组
      },

      // 储存费用信息数组
      xiangxinzengfeilist: [],

      // 合同提交
      zhenghetong: {
        id: '',                         // 采购单id
        type: '2',                      // 1-初审合同   2-正式合同
        contract_name: '',              // 合同标题
        contract_images: '',            // 合同图片
        contract_amounts: '',           // 合同总金额(元)
        contract_amount: '',            // 合同总金额(分)
        contract_auth_user: '',         // 预览合同审批人
        goumai_fapiao: []               // 合同附件数组
      },
      shuzu: ['items', 'fees', 'pays', 'returns'],
      dialogFormVisible: false,
      tableData: [], // 列表数据
      rules: {
        supplier_id: [{ required: true, message: '请选择供应商', trigger: 'change' }],
        company_id: [{ required: true, message: '请选择采购公司', trigger: 'change' }],
        contract_images: [{ required: true, message: '请上传文件', trigger: 'change' }]
      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      canggongsi: []              // 仓库采购公司列表
    }
  },
  mounted() {
    this.Cangsous()               // 仓库列表
    this.Getlists()               // 供应商
    this.Caigoudanlists()         // 采购单列表
    this.InfoGets()               // 获取申请人
    this.Canggongsilist()         // 获取采购公司
    this.Getrenlist()
  },

  methods: {
    notiao() {
      this.$message({
        message: '未上传预审合同',
        type: 'warning'
      })
    },
    nozhengtiao() {
      this.$message({
        message: '未上传正式合同',
        type: 'warning'
      })
    },
    funs() {
      var a = document.getElementById('link')
      a.setAttribute('href', `${this.yupreview_contract}`)
    },
    funzheng() {
      var a = document.getElementById('links')
      a.setAttribute('href', `${this.zhengcontract_images}`)
    },

    // 大取消
    daquxiao() {
      this.caixiangkaiguan = false
      this.form.campany_id = ''
    },
    daqueding() {
      this.Endbiaobaya()
    },
    // 回票确定
    huipiaoqueding() {
      if (this.huipiaoform.auth_user == '') {
        this.$message({
          message: '请选择审核人',
          type: 'warning'
        })
      } else if (this.huipiaoform.bill_type == '') {
        this.$message({
          message: '请选择发票类型',
          type: 'warning'
        })
      } else {
        this.huipiaoya()
      }
    },

    // 回票添加取消
    huipiaoquxiao() {
      this.xinzenghuipiaokaiguan = false
      this.huipiaoform.jiner = ''
      this.huipiaoform.images = ''
      this.huipiaoform.bill_type = ''
      this.huipiaoform.auth_user = ''
      this.goumai_fapiaos = []
      this.huipiaoform.return_list[0].return_money = ''
    },

    // 回票添加窗口默认关闭
    huipiaomorenguan() {
      this.huipiaoquxiao()
    },
    // 点击新建回票按钮
    dianhuipiao() {
      this.xinzenghuipiaokaiguan = true
    },
    // 付款弹窗确定
    fukantainjiaqueding() {
      if (this.fukuan.auth_user == '') {
        this.$message({
          message: '请选择审核人',
          type: 'warning'
        })
      } else {
        this.Xiangfukuanya()
      }
    },

    // 付款弹窗取消
    fukuantianjiaquxiao() {
      this.xinzengfukuan = false
      this.fukuan.pay_list = []
      this.fukuan.auth_user = ''
    },

    // 新增付款申请按钮
    dianfukuanshen() {
      this.xinzengfukuan = true
    },

    // 新增付款信息关闭
    xinzengfukuanguan() {
      this.fukuantianjiaquxiao()
    },

    // 付款审批人选中
    fuxuanzhong(val) {
      this.fukuan.auth_user = val
    },

    // 详情明细提交
    xiangmingxique() {
      if (this.mingxi.num == '') {
        this.$message({
          message: '请输入采购数量',
          type: 'warning'
        })
      } else if (this.mingxi.amount == '') {
        this.$message({
          message: '请输入单价总金额',
          type: 'warning'
        })
      } else if (this.mingxi.receive_time == '') {
        this.$message({
          message: '请选中到货时间',
          type: 'warning'
        })
      } else {
        this.Xiangmingxis()
      }
    },

    // 费用明细提交
    feiyongtainjiaqueding() {
      if (this.xiangxinzengfeilist.length > 0) {
        this.feiform.fee_name = this.xiangxinzengfeilist[0] ? this.xiangxinzengfeilist[0].fee_name : ''
        this.feiform.fee_amount = this.xiangxinzengfeilist[0] ? this.xiangxinzengfeilist[0].fee_amount : ''
        this.feiform.fee_remark = this.xiangxinzengfeilist[0] ? this.xiangxinzengfeilist[0].fee_remark : ''
        this.Xiangfeiyongend()
      } else {
        this.$message({
          message: '请添加数据',
          type: 'warning'
        })
      }
    },

    // 详情明细添加取消
    xiangmingxiquxiao() {
      this.xiangjiaVisible = false
      this.mingxi.num = ''
      this.mingxi.amount = ''
      this.mingxi.item_remark = ''
      this.mingxi.receive_time = ''
      // this.xiangtableData = []
      // this.xiangDaicais()
      // this.$refs.table.clearSelection()     11、2号 做到这了   取消的时候清除表格选中状态
    },

    // 费用添加明细取消
    feiyongtianjiaquxiao() {
      this.xiangfeiyongtan = false
      this.feiform.fee_name = ''
      this.feiform.fee_amount = ''
      this.feiform.fee_remark = ''
      this.xiangxinzengfeilist = []
    },

    // 详情页添加关闭
    xiangqingyeguannnn() {
      this.xiangmingxiquxiao()
    },

    // 判断没有输入数据没办法选中
    selectable(row, index) {
      if (row.hasOwnProperty('num') && row.hasOwnProperty('amount') && row.hasOwnProperty('item_remark') && row.hasOwnProperty('receive_time')) {
        return true
      } else {
        return false
      }
    },

    // 判断没有输入数据没办法选中
    feiselectable(row, index) {
      if (row.hasOwnProperty('fee_name')) {
        return true
      } else {
        return false
      }
    },

    // xianghandleSelectionChange(val) {
    //   console.log(val)
    // },

    handleSelect(selection, val) {
      for (let i = 0; i < this.xiangtableData.length; i++) {
        if (this.xiangtableData[i].id == val.id) {
          this.xiangtableData[i].if = true
          this.mingxi = val
        } else {
          this.xiangtableData[i].if = false
          this.xiangtableData[i].num = ''
          this.xiangtableData[i].amount = ''
          this.xiangtableData[i].item_remark = ''
          this.xiangtableData[i].receive_time = ''
        }
      }
      if (selection.length > 1) {
        let del_row = selection.shift()
        this.$refs.table.toggleRowSelection(del_row, false) //设置这一行取消选中
      }
    },

    // 详情明细增加
    xiangmingjia() {
      this.xiangjiaVisible = true
    },

    // 详情新增费用按钮
    feimingjia() {
      this.xiangfeiyongtan = true
    },
    // 详情新增费用弹窗默认关闭
    xiangfeimoren() {
      this.xiangfeiyongtan = false
      this.feiyongtianjiaquxiao()
    },

    inputCheck(value) {
      return value
        .replace(/^0[0-9]+/, val => val[1])
        .replace(/^(\.)+/, '')
        .replace(/[^\d.]/g, '')
        .replace(/\.+/, '.')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    },
    inputChecks(value) {
      return value
        .replace(/^0[0-9]+/, val => val[1])
        .replace(/^(\.)+/, '')
        .replace(/[^\d.]/g, '')
        .replace(/\.+/, '.')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    },
    feiinputCheck(value) {
      return value
        .replace(/^0[0-9]+/, val => val[1])
        .replace(/^(\.)+/, '')
        .replace(/[^\d.]/g, '')
        .replace(/\.+/, '.')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    },
    huipiaojiner(value) {
      this.huipiaoform.return_list[0].return_money = value
    },
    // 费用合计
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ' 元'
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    // 详情新建审批合同
    xianghe() {
      this.xiangxinheshen = true
    },

    // 详情新建正式合同
    xiangzhenghe() {
      this.zhengxiangxinheshen = true
    },

    // 新增付款申请
    xinfu() {
      this.xinfutan = true
    },

    //添加行号
    zzTableRowClassName({ row, rowIndex }) {
      row.index = rowIndex + 1
    },
    //添加行号
    fuzzTableRowClassName({ row, rowIndex }) {
      row.index = rowIndex + 1
      row.purchase_id = this.purchase_id
    },

    //添加行号
    feizzTableRowClassName({ row, rowIndex }) {
      row.index = rowIndex + 1
    },

    // 多选框选中数据
    handleSelectionChangezz(selection) {
      this.idszz = selection.map(item => item.fid)
    },
    fuhandleSelectionChangezz(selection) {
      this.idszz = selection.map(item => item.fid)
    },
    // 多选框选中数据
    feihandleSelectionChangezz(val) {
      console.log(val)
      this.feiform.fee_name = val[0] ? val[0].fee_name : ''
      this.feiform.fee_amount = val[0] ? val[0].fee_amount : ''
      this.feiform.fee_remark = val[0] ? val[0].fee_remark : ''
    },

    //新增专家行
    handleAddZJ() {
      let obj = {}
      this.dform.other_fees.push(obj)
    },

    // 新增付款费用
    fuhandleAddZJ() {
      let obj = {}
      this.fukuan.pay_list.push(obj)
    },

    feihandleAddZJ() {
      let obj = {}
      if (this.xiangxinzengfeilist.length < 1) {
        this.xiangxinzengfeilist.push(obj)
      } else {
        this.$message({
          message: '一次只能添加一条',
          type: 'warning'
        })
      }
    },

    //删除专家行
    handleDeleteZJ() {
      let arr = this.$refs.zztable.selection
      const arr1 = []
      for (let i = 0; i < arr.length; i++) {
        arr1.push(arr[i].index)
      }
      //数字数组排序，因为选择框是根据先选择的顺序存到checkedDetail里面，所以需要排序
      arr1.sort(function (a, b) {
        return a - b
      })
      for (let i = 0; i < arr1.length; i++) {
        this.dform.other_fees.splice(arr1[i] - i - 1, 1)
      }
    },

    // 付款申请删除
    fuhandleDeleteZJ() {
      let arr = this.$refs.zztable.selection
      const arr1 = []
      for (let i = 0; i < arr.length; i++) {
        arr1.push(arr[i].index)
      }
      //数字数组排序，因为选择框是根据先选择的顺序存到checkedDetail里面，所以需要排序
      arr1.sort(function (a, b) {
        return a - b
      })
      for (let i = 0; i < arr1.length; i++) {
        this.fukuan.pay_list.splice(arr1[i] - i - 1, 1)
      }
    },

    // 费用删除
    feihandleDeleteZJ() {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let arr = this.$refs.zztable.selection
          const arr1 = []
          for (let i = 0; i < arr.length; i++) {
            arr1.push(arr[i].index)
          }
          //数字数组排序，因为选择框是根据先选择的顺序存到checkedDetail里面，所以需要排序
          arr1.sort(function (a, b) {
            return a - b
          })
          for (let i = 0; i < arr1.length; i++) {
            this.xiangxinzengfeilist.splice(arr1[i] - i - 1, 1)
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    // 采购合同申请取消
    hetongxiao() {
      this.dform.contract_name = ''
      this.dform.goumai_fapiao = []
      this.dform.preview_contract_images = ''
      this.dform.contract_amount = ''
      this.contract_amountss = ''
      this.dform.contract_auth_user = ''
      this.innerVisible = false
    },

    // 采购合同确定
    hetongque() {
      this.innerVisible = false
    },

    // 采购合同关闭
    hetongguan() {
      this.hetongxiao()
    },

    // 合同金额
    hetongjiner(val) {
      console.log(val)
      this.dform.contract_amount = val * 100
    },
    // 详情合同金额转元
    hetongjinerss(val) {
      console.log(val)
      this.hetong.contract_amount = val * 100
    },
    // 正式合同
    zhenghetongjinerss(val) {
      console.log(val)
      this.zhenghetong.contract_amount = val * 100
    },
    // 新增合同申请
    xinhe() {
      this.innerVisible = true
    },

    // 查看单条详情
    editfun(e) {
      console.log(e)
      this.form.id = e
      this.huipiaoform.return_list[0].purchase_id = e
      this.purchase_id = e
      this.feiform.purchase_id = e
      this.mingxi.purchase_id = e
      this.hetong.id = e
      this.zhenghetong.id = e
      this.Xiangliat(e)
      this.caixiangkaiguan = true
      this.Dandaicais(e)
      this.Caifushusitems(e)
      this.Caifushusfees(e)
      this.Caifushuspays(e)
      this.Caifushusreturns(e)
      this.xiangDaicais(e)
    },

    // 取消
    quanxiao() {
      // console.log(1)
      this.dform.house_id = ''
      this.dform.supplier_id = ''
      this.dform.goumai_fapiao = []
      this.dform.contract_images = ''
      this.dform.items = []
      this.tableData = []
      this.dform.receive_time = ''
      this.dialogFormVisible = false
      this.hetongxiao()
      this.dform.other_fees = []
    },

    // 确定
    quanque() {
      // console.log(2)
      this.$refs['dform'].validate(v => {
        if (v) {
          console.log(v)
          this.ClientInformations(this.dform)
          this.$refs.dform.resetFields()
          this.quanxiao()
        }
      })
    },

    // 弹窗
    opentan() {
      this.dialogFormVisible = true
      this.Daicais()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogFormVisible = false
      this.quanxiao()
    },

    // 详情新建审批弹窗关闭
    xiangxinheshenguan() {
      this.xiangxinheshen = false
    },
    // 详情正式弹窗关闭
    zhengxiangxinheshenguan() {
      this.zhengxiangxinheshen = false
      this.zhengxianghequxiao()
    },

    // 详情新建审批弹窗取消按钮
    xianghequxiao() {
      this.xiangxinheshen = false
    },

    // 正式合同取消按钮
    zhengxianghequxiao() {
      this.zhengxiangxinheshen = false
      this.zhenghetong.contract_name = ''
      this.zhenghetong.id = '' // 采购单id
      this.zhenghetong.contract_images = '' // 合同图片
      this.zhenghetong.contract_amounts = ' ' // 合同总金额(元)
      this.zhenghetong.contract_amount = '' // 合同总金额(分)
      this.zhenghetong.contract_auth_user = '' // 预览合同审批人
      this.zhenghetong.goumai_fapiao = [] // 合同附件数组
    },

    // 详情新建审批弹窗确定按钮
    xianghequeding() {
      this.Submits()
    },

    // 正式合同弹窗确定按钮
    zhengxianghequeding() {
      this.zhengSubmits()
    },

    // 供应商选择
    chengng(e) {},
    endchengng(e) {},
    // 选中多行
    handleSelectionChange(val) {
      this.dform.items = val
    },
    endhandleSelectionChange(val) {
      // console.log(val);
    },
    zhengendhandleSelectionChange(val) {
      // console.log(val);
    },
    handlePreview(file) {},
    handleExceed(files, fileList) {},
    endhandlePreview(file) {},
    zhengendhandlePreview(file) {},
    endhandleExceed(files, fileList) {},
    zhengendhandleExceed(files, fileList) {},
    // 删除
    beforeRemove(file, fileList, re) {
      return this.$confirm(`确定移除 ${file.name}?`)
    },
    endbeforeRemove(file, fileList, re) {
      return this.$confirm(`确定移除 合同附件吗?`)
    },
    zhengendbeforeRemove(file, fileList, re) {
      return this.$confirm(`确定移除 合同附件吗?`)
    },
    huibeforeRemove(file, fileList, re) {
      return this.$confirm(`确定移除 发票附件吗?`)
    },
    // 切换上传条件
    upyupefun(v) {
      this.steupdata = v
    },
    // 图片上传
    async httpRequest(options) {
      //   console.log(options)
      this.addimgtype = true
      const fd = new FormData()
      fd.append('file[]', options.file)
      const res = await Upimg(fd)
      // console.log(res.data)
      switch (this.steupdata) {
        case '合同附件':
          this.dform.goumai_fapiao.push({ name: res.data[0].name, url: res.data[0].url })
          this.dform.preview_contract_images = res.data[0].url
          //   console.log(this.dform);
          break
      }
      this.addimgtype = false
    },

    async endhttpRequest(options) {
      //   console.log(options)
      this.addimgtype = true
      const fd = new FormData()
      fd.append('file[]', options.file)
      const res = await Upimg(fd)
      console.log(res.data)
      switch (this.steupdata) {
        case '合同附件':
          this.hetong.goumai_fapiao.push({ name: res.data[0].name, url: res.data[0].url })
          this.hetong.contract_images = res.data[0].url
          //   console.log(this.dform);
          break
      }
      this.addimgtype = false
    },
    async zhengendhttpRequest(options) {
      //   console.log(options)
      this.addimgtype = true
      const fd = new FormData()
      fd.append('file[]', options.file)
      const res = await Upimg(fd)
      console.log(res.data)
      switch (this.steupdata) {
        case '合同附件':
          this.zhenghetong.goumai_fapiao.push({ name: res.data[0].name, url: res.data[0].url })
          this.zhenghetong.contract_images = res.data[0].url
          //   console.log(this.dform);
          break
      }
      this.addimgtype = false
    },

    async huihttpRequest(options) {
      //   console.log(options)
      this.addimgtype = true
      const fd = new FormData()
      fd.append('file[]', options.file)
      const res = await Upimg(fd)
      console.log(res.data)
      switch (this.steupdata) {
        case '发票附件':
          this.goumai_fapiaos.push({ name: res.data[0].name, url: res.data[0].url })
          this.huipiaoform.images = res.data[0].url
          //   console.log(this.dform);
          break
      }
      this.addimgtype = false
    },
    // 删除图片
    handleRemove(file, fileList, re) {
      re.forEach((item, i) => {
        if (item.uid === file.uid) {
          re.splice(i, 1)
          this.dform.preview_contract_images = ''
        }
      })
    },
    endhandleRemove(file, fileList, re) {
      re.forEach((item, i) => {
        if (item.uid === file.uid) {
          re.splice(i, 1)
          this.hetong.contract_images = ''
        }
      })
    },
    zhengendhandleRemove(file, fileList, re) {
      re.forEach((item, i) => {
        if (item.uid === file.uid) {
          re.splice(i, 1)
          this.zhenghetong.contract_images = ''
        }
      })
    },

    huihandleRemove(file, fileList, re) {
      re.forEach((item, i) => {
        if (item.uid === file.uid) {
          re.splice(i, 1)
          this.huipiaoform.images = ''
          this.goumai_fapiaos = []
        }
      })
    },

    // 详情页关闭
    daxiangguan() {
      this.caixiangkaiguan = false
      this.hetong.contract_name = ''
      this.hetong.id = '' // 采购单id
      this.hetong.contract_images = '' // 合同图片
      this.hetong.contract_amounts = ' ' // 合同总金额(元)
      this.hetong.contract_amount = '' // 合同总金额(分)
      this.hetong.contract_auth_user = '' // 预览合同审批人
      this.hetong.goumai_fapiao = [] // 合同附件数组
    },

    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Caigoudanlists()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Caigoudanlists()
    },

    // 代采购列表
    async Daicais() {
      const { data } = await Daicai()
      this.tableData = data.data
    },
    // // 详情代采购列表
    // async xiangDaicais(purchase_id) {
    //   const { data } = await Daicai({ purchase_id: purchase_id })
    //   let arrs = data.data
    //   for (const i in arrs) {
    //     arrs[i].if = false
    //     arrs[i].item_id = '0'
    //     arrs[i].purchase_id = purchase_id
    //   }
    //   this.xiangtableData = arrs
    //   console.log(this.xiangtableData)
    // },

    // 详情代采购列表
    async xiangDaicais(purchase_id) {
      const { data } = await Daicai({ purchase_id: purchase_id })
      let arrs = data.data
      for (const i in arrs) {
        arrs[i].if = false
        arrs[i].item_id = '0'
        arrs[i].purchase_id = purchase_id
      }
      arrs.map(item => {
        item.num = ''
        item.amount = ''
        item.item_remark = ''
        item.receive_time = ''
      })
      this.xiangtableData = JSON.parse(JSON.stringify(arrs))

      console.log(this.xiangtableData, 'aaaaa')
    },

    // 仓库下拉
    async Cangsous() {
      const { data } = await Cangsou()
      this.canglist = data.data
    },
    // 供应商
    async Getlists() {
      const { data } = await Gongyingsou()
      this.gonglist = data.data
    },

    // 创建采购单
    async ClientInformations(v) {
      const { data } = await Setdan(v)
      this.getanpostfun(data, '添加', this.Caigoudanlists())
    },

    // 采购单列表
    async Caigoudanlists() {
      const { data } = await Caigoudanlist({ ...this.pageData })
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
      this.caigoudanliat = data.data.data
    },

    // 采购单列表详情
    async Xiangliat(id) {
      const { data } = await Xiangqing({ id })
      this.purchase_user = data.data.purchase_user // 购买的用户
      this.supplier = data.data.supplier // 供应商信息
      this.form.company_id = data.data.company.id // 采购公司的信息
      this.form.supplier_id = data.data.supplier.id
      this.yupreview_contract = data.data.preview_contract ? data.data.preview_contract[0] : ''
      console.log('预览合同' + this.yupreview_contract)
      this.zhengcontract_images = data.data.contract_images  ? data.data.contract_images[0] : ''
      console.log('正式合同' + this.zhengcontract_images)
      this.hetong.contract_name = data.data.contract_name
      this.fukuan.supplier_id = data.data.supplier.id
      this.huipiaoform.supplier_id = data.data.supplier.id
      this.hetong.contract_images = data.data.preview_contract ? data.data.preview_contract[0] : ''
      this.hetong.contract_amounts = data.data.contract_amount != 0 ? data.data.contract_amount : ''
      this.hetong.contract_amount = data.data.contract_amount * 100
      this.hetong.contract_auth_user = data.data.contract_auth_user
      this.need_payed_amount = data.data.need_payed_amount ? data.data.need_payed_amount : ''
      this.need_returned_amount = data.data.need_returned_amount ? data.data.need_returned_amount : ''
      console.log(this.need_payed_amount)
      this.allow_preview_contract = data.data.allow_preview_contract
      this.allow_release_contract = data.data.allow_release_contract
      this.contract_status = data.data.contract_status

      if (data.data.preview_contract.length !== 0) {
        var obj = new Object()
        obj.url = data.data.preview_contract[0]
        obj.name = '合同附件'
        this.hetong.goumai_fapiao.push(obj)
      } else {
        this.hetong.goumai_fapiao = []
      }
    },

    // 采购单附属信息
    async Caifushusitems(purchase_id) {
      const { data } = await Caifushu({ purchase_id: purchase_id, type: 'items' })
      this.items = data.data.items
    },
    // 采购单附属信息
    async Caifushusfees(purchase_id) {
      const { data } = await Caifushu({ purchase_id: purchase_id, type: 'fees' })
      this.feiyonglist = data.data.fees.list
    },
    // 采购单附属信息
    async Caifushuspays(purchase_id) {
      const { data } = await Caifushu({ purchase_id: purchase_id, type: 'pays' })
    },
    // 采购单附属信息
    async Caifushusreturns(purchase_id) {
      const { data } = await Caifushu({ purchase_id: purchase_id, type: 'returns' })
    },

    // 获取申请人
    async InfoGets() {
      const { data } = await InfoGet()
      this.shenname = data.data.name
    },

    // 仓库采购公司列表
    async Canggongsilist(v) {
      const { data } = await Canggongsi()
      this.canggongsi = data.data
    },

    // 人员列表
    async Getrenlist() {
      const { data } = await Shenheren()
      this.renlist = data.data
    },

    // 详情代采购列表
    async Dandaicais(purchase_id) {
      const { data } = await Daicai({ purchase_id: purchase_id })
    },

    // 详情采购合同提交
    async Submits() {
      const { data } = await Submit({ ...this.hetong })
      this.getanpostfun(data, '提交', this.xiangxinheshenguan())
    },

    // 正式合同
    async zhengSubmits() {
      const { data } = await Submit({ ...this.zhenghetong })
      this.getanpostfun(data, '提交', this.zhengxianghequxiao())
    },

    // 详情明细添加接口
    async Xiangmingxis() {
      const { data } = await Xiangmingxi({ ...this.mingxi })
      this.getanpostfun(data, '添加', this.xiangmingxiquxiao(), this.xiangDaicais(this.purchase_id), this.Caifushusitems(this.mingxi.purchase_id))
    },

    // 详情费用提交
    async Xiangfeiyongend() {
      const { data } = await Xiangfeiyong({ ...this.feiform })
      this.getanpostfun(data, '新增', this.xiangfeimoren(), this.Caifushusfees(this.feiform.purchase_id))
    },

    // 详情付款明细提交
    async Xiangfukuanya() {
      const { data } = await Xiangfukuan({ ...this.fukuan })
      this.getanpostfun(data, '新增', this.xinzengfukuanguan())
    },

    // 详情付款明细提交
    async huipiaoya() {
      const { data } = await huipiaotijiao({ ...this.huipiaoform })
      this.getanpostfun(data, '新增', this.huipiaomorenguan())
    },

    // 采购单顶部编辑
    async Endbiaobaya() {
      const { data } = await Endbiaoba({ ...this.form })
      this.getanpostfun(data, '修改', this.daxiangguan())
    },

    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`, this.handleClose())
      }
    }
  }
}
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -o-appearance: none !important;
  -ms-appearance: none !important;
  appearance: none !important;
  margin: 0;
}
input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  -o-appearance: textfield;
  -ms-appearance: textfield;
  appearance: textfield;
}
.btn {
  width: 300px;
  display: flex;
}
.tan {
  display: flex;
  align-items: center;
}
.tans {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.let {
  width: 70px;
  margin-left: 15px;
  margin-right: 10px;
}
.btnshang {
  display: flex;
  margin-top: 15px;
  margin-left: 60px;
}
.title {
  margin-top: 20px;
}
.title button {
  margin-left: 20px;
}
.gong {
  margin-top: 30px;
}
.hebox {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.asas {
  width: 150px;
  height: 130px;
  /* border: 1px solid #000; */
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  background-color: #409eff;
  text-decoration: none;
}
.right-box {
  width: 30%;
  display: flex;
}

.asass {
  width: 150px;
  height: 130px;
  /* border: 1px solid #000; */
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  background-color: #67c23a;
  text-decoration: none;
}
.rig {
  margin-left: 20px;
}
</style>